<template>
    <router-link :to="to" class="text-white text-opacity-90 text-xs hover:text-rojo hover:text-opacity-100 font-medium py-6 px-1" :class="route.path==to ? 'text-rojo' : 'text-white'">
        {{ text }}
    </router-link>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'

export default {
    props: ['text', 'to'],
    setup() {
        const router = useRouter();
        const route = useRoute();

        return {
            route
        }
        
    },
}
</script>