<template>
<Popover>
    <nav class="relative flex items-center md:border-b border-gray-600">
        <div class="flex items-center justify-between w-full md:w-auto pr-6">
            <router-link to="/">
                <img class="w-28 md:w-32" :src="require('@/assets/core/menu/logo_redpepper.jpg')" alt="Red Pepper">
            </router-link>
            <div class="-mr-2 flex items-center md:hidden">
                <PopoverButton class="bg-rojo rounded-md mt-2 p-2 inline-flex items-center justify-center text-morado hover:text-white hover:bg-rojo focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black">
                    <span class="sr-only">Abrir menú</span>
                    <MenuIcon class="h-5 w-5" aria-hidden="true" />
                </PopoverButton>
            </div>
        </div>


        <div class="hidden md:block ml-10 space-x-6">
            <Link text="GRANDES IDEAS" to="/grandes-ideas" />
            <Link text="EXPERIENCIAS" to="/experiencias" />
            <Link text="LO MEJOR" to="/lo-mejor" />
            <Link text="NOSOTROS" to="/nosotros" />
            <!-- <Link text="CONTACTO" to="/contacto" /> -->
        </div>
    </nav>

    <transition enter-active-class="duration-150 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
        <PopoverPanel focus class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-30">
            <div class="rounded-lg shadow-md bg-rojo ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div class="px-5 pl-7 pt-6 flex items-center justify-between">
                    <router-link to="/">
                        <img class="w-20" :src="require('@/assets/core/footer/logo_redpepper_blanco.png')" alt="Red Pepper">
                    </router-link>
                    <div class="-mr-2">
                        <PopoverButton class="bg-rojo rounded-md p-2 inline-flex items-center justify-center text-white hover:text-white hover:bg-rojo focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                            <span class="sr-only">Cerrar menú</span>
                            <XIcon class="h-6 w-6" aria-hidden="true" />
                        </PopoverButton>
                    </div>
                </div>

                <div class="pl-6 pt-10 pb-6 space-y-2 text-white text-lg font-medium">
                    <router-link to="/" class="block">INICIO</router-link>
                    <router-link to="/grandes-ideas" class="block">GRANDES IDEAS</router-link>
                    <router-link to="/experiencias" class="block">EXPERIENCIAS</router-link>
                    <router-link to="/lo-mejor" class="block">LO MEJOR</router-link>
                    <router-link to="/nosotros" class="block">NOSOTROS</router-link>
                    <!-- <router-link to="/contacto" class="block">CONTACTO</router-link> -->
                </div>
            </div>
        </PopoverPanel>
    </transition>
</Popover>
</template>

<script>
import Link from '@/components/core/Link'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { MenuIcon, XIcon, ArrowNarrowRightIcon } from '@heroicons/vue/outline'
import { ChevronRightIcon } from '@heroicons/vue/solid'

export default {
    components: {
        Link,
		Popover,
		PopoverButton,
		PopoverPanel,
		MenuIcon,
		XIcon,
		ChevronRightIcon,
		ArrowNarrowRightIcon
	},
    setup() {
        
    },
}
</script>

